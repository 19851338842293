import React, { useEffect, useRef } from 'react';
import Form from './Form'
import styled from 'styled-components';
import { gsap } from "gsap";
import Container from './container'
import Obfuscate from 'react-obfuscate'

// gsap.registerPlugin(ScrollTrigger)

const Wrapper = styled.div`
h1{
white-space: nowrap;
    text-transform: capitalize;
}

h2,h1,p {
    padding-bottom: 1.25rem;
    text-align: center;
}

.pt-100{
    padding-top:100px;
}

button{
    background-color: #fff;
    border: none;
    color: #181718;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 600;
}

`


const Contact = (props) => {

    const ref = useRef(null);

    useEffect(() => {

        const element = ref.current;

        gsap.to(element.querySelector(".bg"), {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
                trigger: element,
                scrub: true,
                scroller: "body"
            }
        });

    }, []);



    return <div ref={ref}>
        <Wrapper>
            <Container reversed direction="column">
                <h2 class="o-title" data-scroll data-scroll-offset="150"><span class="o-title_line"> <span>Join the Revolution</span></span></h2>
                <p class="o-title" data-scroll data-scroll-offset="150"><span class="o-title_line"> <span>Let's get in touch. Contact us at <Obfuscate email="info@vertual.tv" /> or fill the form</span></span></p>
            </Container>
            <Container vh>
                <Form />
            </Container>
            <Container direction="column">
                <div className="pt-100">
                    <h2 class="o-title" data-scroll data-scroll-offset="150"><span class="o-title_line"><span>Join the team</span></span></h2>
                    <p class="o-title" data-scroll data-scroll-offset="150"><span class="o-title_line"><span>Do you want to be a part of our team? send in your CV and portfolio to <Obfuscate email="jointhereviolution@vertual.tv" /></span></span></p>
                </div>
            </Container>
        </Wrapper>
    </div>
};

export default Contact;