import React, { useRef, useEffect, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'gatsby';
import axios from 'axios';
import styled from 'styled-components';
import Arrow from '../assets/arrow.svg';
import { gsap } from "gsap";
import ScrollContext from '../context/scrollContext'


const FormStyle = styled.div`
color:#fff;
padding: 5rem 5rem 7.5rem;

.flex{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

h3{
    text-align: center;
}

.policy p{
    font-size:16px;
    margin-bottom:0;
    padding-bottom:0;
}

svg{
    width:30px;
    height: auto;
}

.column{
    display: flex;
    flex-flow: wrap;
}
.form-input {
  margin: 60px 0;
  width: 100%;
}
.form-input label {
  position: relative;
  display: block;
  width: 100%;
}
.form-input .placeholder {
  position: absolute;
  display: block;
  top: -5px;
  left: .5rem;
  z-index: 2;
  font-size: 16px;
  transition: all 200ms ease-in-out;
  width: 100%;
  cursor: text;
}
.form-input input,
.form-input textarea {
  z-index: 1;
  width: 100%;
  font-size: 16px;
  border: 0;
  border-bottom: 1px solid grey;
  transition: border-color 200ms ease-in-out;
  outline: none;
  padding: 0;
  margin: 0;
  background: transparent;
  color: #fff;
}
.form-input textarea {
  min-height: 332px;
  padding: 8px 0;
}
.form-input input {
  height: 30px;
}
.form-input input:focus,
.form-input input:valid,
.form-input textarea:focus,
.form-input textarea:valid {
  border-bottom: 1px solid #fff;
}
.form-input input:focus + .placeholder,
.form-input input:valid + .placeholder,
.form-input textarea:focus + .placeholder,
.form-input textarea:valid + .placeholder {
  top: -20px;
  left: 0;
  cursor: inherit;
  font-size: 12px;
  opacity: 0.6;
}

.left{
    width: 40%;
    padding: 0 5%;
}
.right{
    width: 40%;
    padding: 0 5%;
}

.policy{
    display: flex;
    align-items: center;
    padding: 0 5%;
}

.submit{
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-top: 2rem;
}

.submit button{
    border-radius: 100%;
    width: 100px;
    height: 100px;
    border-style: none;
    transition: 1s;
    cursor: pointer;
}

.submit button:hover{
    transform: scale(1.3) rotate(20deg);
}

.submit button:hover + svg{
    transform: translateX(20);
}


.checkbox{
    margin-right: 1rem;
}

.error{
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 3px;
    position: relative;
    left: .5rem;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
    -webkit-letter-spacing: 0.03333em;
    -moz-letter-spacing: 0.03333em;
    -ms-letter-spacing: 0.03333em;
    letter-spacing: 0.03333em;
    height: 0.75rem;
}


@media (max-width:1280px){
    .left{
    width: 100%;
}
.form-input textarea {
  min-height: 152px;
  padding: 8px 0;
}

.right{
    width: 100%;
}

.form-input {
  margin: 20px 0;
  width: 100%;
}

h3{text-align: center;
    padding-bottom: 2rem;
    font-size: 2rem;
}

.submit{
    justify-content: center;
}

}


@media (max-width:768px){
    padding: 2.5rem 2.5rem 5rem;

    .submit button {
    top: 31px;
}
}

@media (max-width:512px){
    padding: 2.5rem 1rem 5rem;
    h6 {
    font-size: 0.6rem;
}

}
`;

const schema = yup.object().shape({
    name: yup.string().required("Name required"),
    email: yup.string().email("Invalid email address").required("Email required"),
    phone: yup
        .string()
        .matches(/^[0-9]+$/, "Telephone required")
        .min(7, 'Invalid Telephone Number')
        .max(10, 'Invalid Telephone Number')
        .required("Telephone required"),
    message: yup.string().required("Message required"),
    company: yup.string().required("Company required"),
    terms: yup.bool().oneOf([true], 'Accetta informativa per la privacy')
});

const Form = (props) => {
    const [submitted, setSubmitted] = useState("false");
    const {
        register,
        handleSubmit,
        setError,
        reset,
        clearError,

        formState: {
            isSubmitting,
            errors,
        },
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onSubmit = data => {
        axios({
            method: 'post',
            url: "../mail.php",
            headers: { 'content-type': 'application/json' },
            data: data
        })
            .then(result => {
                if (result.data.status === 'success') {
                    reset();
                    setSubmitted("true");
                }
                else {
                    setError('submit', 'submitError', `An error has occurred. Please try again`);
                }
            })
    };

    const ref = useRef(null);
    const revealRefs = useRef([]);
    revealRefs.current = [];
    const scrollContext = useContext(ScrollContext);

    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    }


    useEffect(() => {

        if (scrollContext.scroll) {

            const element = ref.current;

            revealRefs.current.forEach((el, index) => {

                gsap.fromTo(el.children, {
                    width: 0,
                }, {
                    duration: 1,
                    width: '100%',
                    ease: 'power2',
                    delay: index * 0.2,
                    scrollTrigger: {
                        id: `section-${index + 1}`,
                        trigger: element,
                        start: 'top center+=100',
                        toggleActions: 'play none none none',
                    }
                });

            });

            revealRefs.current.forEach((el, index) => {

                gsap.fromTo(el.children, {
                    autoAlpha: 0,
                }, {
                    duration: 1.5,
                    autoAlpha: 1,
                    ease: 'power2',
                    delay: index * 0.2,
                    scrollTrigger: {
                        id: `section-${index + 1}`,
                        trigger: element,
                        start: 'top center+=100',
                        toggleActions: 'play none none none',
                    }
                });

            });

            gsap.fromTo(element.querySelector(".policy"), {
                autoAlpha: 0,
            }, {
                duration: 1,
                autoAlpha: 1,
                ease: 'power2',
                delay: 1.2,
                scrollTrigger: {
                    trigger: element,
                    start: 'top center+=100',
                    toggleActions: 'play none none none',
                }
            });

            revealRefs.current.forEach((el, index) => {

                gsap.fromTo(el.children, {
                    width: 0,
                }, {
                    duration: 1,
                    width: '100%',
                    ease: 'power2',
                    delay: index * 0.2,
                    scrollTrigger: {
                        id: `section-${index + 1}`,
                        trigger: element,
                        start: 'top center+=100',
                        toggleActions: 'play none none none',
                    }
                });

            });

            revealRefs.current.forEach((el, index) => {

                gsap.fromTo(el.children, {
                    autoAlpha: 0,
                }, {
                    duration: 1.5,
                    autoAlpha: 1,
                    ease: 'power2',
                    delay: index * 0.2,
                    scrollTrigger: {
                        id: `section-${index + 1}`,
                        trigger: element,
                        start: 'top center+=100',
                        toggleActions: 'play none none none',
                    }
                });

            });

            gsap.fromTo(element.querySelector(".policy"), {
                autoAlpha: 0,
            }, {
                duration: 1,
                autoAlpha: 1,
                ease: 'power2',
                delay: 1.2,
                scrollTrigger: {
                    trigger: element,
                    start: 'top center+=100',
                    toggleActions: 'play none none none',
                }
            });

        }
    }, [scrollContext]);


    const showSubmitError = msg => <p className="text-center">{msg}</p>;

    const showThankYou = (
        <div className="msg-confirm">
            <p className="text-center">Your message has been sent. Thank you.</p>
            <div className="flex" style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center"
            }}>
                <button onClick={() => { setSubmitted("false"); clearError() }}>send another message</button>
            </div>
        </div>
    );

    const showForm = (
        <FormStyle>
            <div ref={ref}>
                <form onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="column">
                        <div className="left">
                            <div className="form-input" ref={addToRefs}>
                                <label>
                                    <input {...register('name')} required />
                                    <span className="placeholder">Name</span>
                                </label>
                                <p className="error">{errors.name && errors.name.message}</p>
                            </div>
                            <div className="form-input" ref={addToRefs}>
                                <label>
                                    <input {...register('phone')} required />
                                    <span className="placeholder">Telephone</span>
                                </label>
                                <p className="error">{errors.phone && errors.phone.message}</p>
                            </div>
                            <div className="form-input" ref={addToRefs}>
                                <label>
                                    <input {...register('email')} required />
                                    <span className="placeholder">Email</span>
                                </label>
                                <p className="error">{errors.email && errors.email.message}</p>
                            </div>
                            <div className="form-input" ref={addToRefs}>
                                <label>
                                    <input {...register('company')} required />
                                    <span className="placeholder">Company</span>
                                </label>
                                <p className="error">{errors.company && errors.company.message}</p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="form-input" ref={addToRefs}>
                                <label>
                                    <textarea {...register('message')} required></textarea>
                                    <span className="placeholder">Your message</span>
                                </label>
                                <p className="error">{errors.message && errors.message.message}</p>
                            </div>
                        </div>
                        <div className="policy">
                            <input
                                type="checkbox"
                                className="checkbox"
                                {...register('terms')}
                                required
                                disabled={isSubmitting} />
                            <p>I agree my personal data will be used by Vertual only for purposes related to the service/contact requested.
                                Consult our <Link to="/privacy-policy">Privacy Policy</Link> for further information and to contact Vertual for privacy concerns and requests.</p>
                            <p className="error">{errors.terms && errors.terms.message}</p>
                        </div>
                    </div>

                    <div className="submit">
                        <button type="submit" disabled={isSubmitting}>
                            <Arrow />
                        </button>
                    </div>
                </form>
            </div>
        </FormStyle>
    );


    return (
        <div className="page contact-page">
            <div className="text-side">
                {errors && errors.submit && showSubmitError(errors.submit.message)}
            </div>
            <div className="form-side">{submitted === "true" ? showThankYou : showForm}</div>
        </div>


    );

};

export default Form;