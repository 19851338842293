import React from 'react';
import styled from 'styled-components';
import Layout from "../layouts";
import Hover from "../components/hover";
import Contact from "../components/contact";
import VideoMockup from "../components/videoMockup";
import Footer from "../components/footer";
import ArrowDown from "../components/arrowDown";
import AnimatedText from '../components/animatedText';
import Seo from "../components/seo"

const Wrapper = styled.div`
.flux > div{
    padding-top: 100px;
}
.flux > div:first-child{
    padding-top: 0;
}

`

const SuperTitle = styled.div`
color: #fff;
z-index: 1;
justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
    max-height: -webkit-fill-available;
    width: 100vw;
    flex-direction:column;
`
const ContactPage = (props) => {

  return <>
    <div>
      <Layout>
        <Seo title="Contact Us - Vertual" />
        <Hover type="">
          <main data-scroll data-scroll-id="update">
            <ArrowDown />
            <div className="kaleido">
              <VideoMockup video={"videoContact"} fullpage />
            </div>
            <Wrapper>
              <div className="flux">
                <SuperTitle className="home">
                  <AnimatedText tag="h1-main">Contact Us</AnimatedText>
                </SuperTitle>
                <Contact />
                <Footer page="not" />
              </div>
            </Wrapper>
          </main>
        </Hover>
      </Layout>
    </div>
  </>
};


export default ContactPage;